import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { Inline, Stack, xcss, Box } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { AIAttributionFooter } from '@atlassian/ai-components';

import { fg } from '@confluence/feature-gating';

import { CreatePageButton } from '../CreatePageModal/CreatePageButton';

import { CopySummaryButton } from './CopySummaryButton';
import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';
import { SmartsFeedbackComponent } from './SmartsFeedbackComponent';
import { RefreshSummaryButton } from './RefreshSummaryButton';

const copyButtonStyles = xcss({ height: '24px' });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FooterWrapper = styled.div({
	borderTop: `1px solid ${token('color.border')}`,
	paddingInline: token('space.200'),
	paddingBlock: token('space.050'),

	// There's currently a bug with the split button thats causing child buttons
	// to inherit the styling of the split button. Need to use styled component and !important to override the styles
	// Platform ticket to fix the bug: https://product-fabric.atlassian.net/browse/DSP-19088
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		/* eslint-disable @atlaskit/ui-styling-standard/no-important-styles */
		color: `${token('color.text.subtlest')} !important`,
		fontSize: '11px !important',
		'&:hover': {
			color: `${token('color.text.selected')} !important`,
			cursor: 'pointer !important',
		},
		/* eslint-enable @atlaskit/ui-styling-standard/no-important-styles */
	},
});

const stackStyles = xcss({
	color: 'color.text.subtlest',
	fontSize: '14px',
});

export type AISummaryFooterProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	contentType?: string;
	summaryText?: string;
	error?: boolean;
	showBetaLozenge?: boolean;
	refreshHandler?: () => void;
	shouldHighlightRefresh?: boolean;
	isHidden?: boolean;
};

export const AISummaryFooter: FC<AISummaryFooterProps> = ({
	analyticsProperties,
	contentId,
	contentType,
	summaryText,
	error,
	showBetaLozenge,
	refreshHandler,
	shouldHighlightRefresh,
	isHidden = false,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showCreatePageButton =
		contentType === 'whiteboard' && fg('cc_whiteboard_to_page_conversion');

	const onLearnMoreClicked = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}LearnWhyLink`,
				attributes: {
					contentId,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
	};

	if (isHidden) {
		return null;
	}

	return (
		<FlagsProvider>
			<Stack space="space.200" xcss={stackStyles}>
				{!error && (
					<Box paddingInline="space.200">
						<Inline alignBlock="center" spread="space-between">
							<Inline space="space.050" alignBlock="center">
								<SmartsFeedbackComponent
									analyticsProperties={analyticsProperties}
									contentId={contentId}
								/>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
								{!!summaryText && (
									<Box xcss={copyButtonStyles}>
										<CopySummaryButton
											analyticsProperties={analyticsProperties}
											contentId={contentId}
											summaryText={summaryText}
										/>
									</Box>
								)}
								{refreshHandler && (
									<RefreshSummaryButton
										analyticsProperties={analyticsProperties}
										contentId={contentId}
										onRefreshClicked={refreshHandler}
										shouldHighlightRefresh={shouldHighlightRefresh}
									/>
								)}
							</Inline>
							{showCreatePageButton && !!summaryText && (
								<CreatePageButton summaryText={summaryText} />
							)}
						</Inline>
					</Box>
				)}
				<FooterWrapper>
					<AIAttributionFooter showBetaLozenge={showBetaLozenge} onLinkClick={onLearnMoreClicked} />
				</FooterWrapper>
			</Stack>
		</FlagsProvider>
	);
};
