import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { CreatePageModal } from './CreatePageModal';
import { useCreatePageModalContext } from './CreatePageModalContextProvider';

const i18n = defineMessages({
	createPageButton: {
		id: 'quick-summary.create-page-button',
		defaultMessage: 'Create page',
		description: 'Button in quick summary footer to launch modal for converting summary to page',
	},
});

export type CreatePageButtonProps = {
	summaryText: string;
};

const CreatePageButtonComponent: FC<CreatePageButtonProps> = ({ summaryText }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { trackAIResultAction } = useAIEventsInstrumentation();

	const openModal = useCallback(() => {
		trackAIResultAction('createPageButtonClicked');
		setIsModalOpen(true);
	}, [trackAIResultAction]);

	const closeModal = useCallback(() => {
		trackAIResultAction('createPageModalClosed');
		setIsModalOpen(false);
	}, [trackAIResultAction]);

	const { formatMessage } = useIntl();

	const { contentId, spaceKey, contentTitle } = useCreatePageModalContext();

	if (!spaceKey || !contentId || !contentTitle) {
		return null;
	}

	return (
		<>
			<Button appearance="primary" onClick={openModal}>
				{formatMessage(i18n.createPageButton)}
			</Button>
			{isModalOpen && (
				<CreatePageModal
					onClose={closeModal}
					summaryText={summaryText}
					spaceKey={spaceKey}
					contentId={contentId}
					contentTitle={contentTitle}
				/>
			)}
		</>
	);
};

export const CreatePageButton = (props: CreatePageButtonProps) => {
	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<CreatePageButtonComponent {...props} />
		</ErrorBoundary>
	);
};
